import axios from "axios";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { formatToCPF, formatToDate, formatToPhone, isCPF } from "brazilian-values";
import { Field, Form, Formik } from "formik";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  ProgressBar,
  Row,
  Tab,
  Tabs
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsSearch } from "react-icons/bs";
import { MdError, MdFileDownload } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  AlertaErro,
  CheckFieldPadrao,
  DocumentoField,
  ErroField,
  InputFieldPadrao,
  InputFieldTelefone,
  InputLegenda,
  SelectFieldOnChange,
  SelectFieldPadrao,
} from "../../components/field";
import Footer from "../../components/footer";
import { Hint } from "../../components/hint";
import {
  ModalJornadas,
  ModalSalario
} from "../../components/modal";
import Navegacao from "../../components/navbar";
import { GlobalContext } from "../../contexts/global";
import LogoArquivo from "../../images/logo.svg";
import { planilhaBancos } from "../../utils/dadosDeBancos";
import { ESCOLARIDADE } from "./escolaridade";
import { ESTADO_CIVIL } from "./estado_civil";
import { RACA_COR } from "./raca_cor";
import { SEXO } from "./sexo";

function Admissao() {
  const { conexao } = useContext(GlobalContext);

  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [msgLoading, setMsgLoading] = useState("");
  const [telaAlerta, setTelaAlerta] = useState(false);
  const [telaMsg, setTelaMsg] = useState("");
  const [telaTitulo, setTelaTitulo] = useState("");
  const [tabAtiva, setTabAtiva] = useState("dados-pessoais");

  const [progresso] = useState(0);

  const [valoresIniciais, setValoresIniciais] = useState({});

  const [listaFilhos, setListaFilhos] = useState([]);
  const [listaJornadas, setListaJornadas] = useState([]);

  const empresaSelecionada = localStorage.getItem("razao");

  const empresasUsuarioTemAcesso = JSON.parse(localStorage.getItem("empresas"));
  const [idEmpresaSelecionada, setIdEmpresaSelecionada] = useState(
    localStorage.getItem("id_empresa")
  );

  const [isOpenModalSalario, setIsOpenModalSalario] = useState(false);
  const [estaAbertoModalJornadas, setEstaAbertoModalEscalas] = useState(false);
  const [diaSelecionadoJornada, setDiaSelecionadoJornada] = useState("");
  const [diaDescricao, setDiaDescricao] = useState("");
  const [inputDiaAtual, setInputDiaAtual] = useState("");
  const [criarCargo, setCriarCargo] = useState(false);

  const [isOpenModalHorarios, setIsOpenModalHorarios] = useState(false);
  const [criarEscala, setCriarEscala] = useState(false);

  const [telaNovoFilho, setTelaNovoFilho] = useState(false);
  const [telaNovaJornada, setTelaNovaJornada] = useState(false);

  const [jornadaDias, setJornadaDias] = useState(null);
  const [jornadaHorarios, setJornadaHorarios] = useState(null);
  const [jornadaObs, setJornadaObs] = useState(null);

  const [erroData, setErroData] = useState(false);
  const [erroMsg, setErroMsg] = useState("");

  const [listaAnexos, setListaAnexos] = useState([])

  const [viewTicket, setViewTicket] = useState(false)

  const formRef = useRef();

  const urlParams = new URLSearchParams(window.location.search)

  const { id } = useParams();

  const SchemaFilhos = Yup.object().shape({
    nome_filho: Yup.string().required("Campo Obrigatório"),
    cpf_filho: Yup.string()
      .required("Campo Obrigatório")
      .min(11, "CPF deve ter 11 digitos")
      .max(11, "CPF deve ter 11 digitos"),
    data_nascimento_filho: Yup.date().required("Campo Obrigatório"),
  });

  const SchemaAdmissao = Yup.object().shape({
    //Dados Pessoais
    admi_nome: Yup.string().required("Campo Obrigatório").nullable(),

    admi_data_nascimento: Yup.date().required("Campo Obrigatório").nullable(),

    admi_data_admissao: Yup.date().required("Campo Obrigatório").nullable(),
    
    admi_raca_cor: Yup.string().required("Campo Obrigatório").nullable(),

    admi_cpf: Yup.string()
      .min(11, "CPF deve ter 11 digitos")
      .max(11, "CPF deve ter 11 digitos")
      .required("Campo Obrigatório")
      .nullable(),

    admi_pis: Yup.string()
      .min(11, "PIS deve ter 11 digitos")
      .max(11, "PIS deve ter 11 digitos")
      .required("Campo Obrigatório")
      .nullable(),

    admi_nome_mae: Yup.string().nullable(),
    // .required('Campo Obrigatório'),

    admi_nome_pai: Yup.string().nullable(),
    // .required('Campo Obrigatório'),

    //Endereço
    admi_end_cep: Yup.string()
      .required("Campo Obrigatório")
      .min(8, "CEP deve ter 8 digitos")
      .nullable(),

    admi_end_endereco: Yup.string().required("Campo Obrigatório").nullable(),

    admi_end_numero: Yup.number().required("Campo Obrigatório").nullable(),

    admi_end_bairro: Yup.string().required("Campo Obrigatório").nullable(),

    admi_end_cidade: Yup.string().required("Campo Obrigatório").nullable(),

    admi_end_uf: Yup.string()
      .required("Campo Obrigatório")
      .min(2, "UF deve ter 2 digitos")
      .max(2, "UF deve ter 2 digitos")
      .nullable(),

    admi_end_complemento: Yup.string().nullable(),
    // .required('Campo Obrigatório'),

    //Jornada
    admi_jornada_trab_seg: Yup.string()
      .required("Campo Obrigatório")
      .nullable(),
    admi_jornada_trab_ter: Yup.string()
      .required("Campo Obrigatório")
      .nullable(),
    admi_jornada_trab_qua: Yup.string()
      .required("Campo Obrigatório")
      .nullable(),
    admi_jornada_trab_qui: Yup.string()
      .required("Campo Obrigatório")
      .nullable(),
    admi_jornada_trab_sex: Yup.string()
      .required("Campo Obrigatório")
      .nullable(),
    admi_jornada_trab_sab: Yup.string()
      .required("Campo Obrigatório")
      .nullable(),
    admi_jornada_trab_dom: Yup.string()
      .required("Campo Obrigatório")
      .nullable(),

    //Adicionais
    admi_cargo: Yup.string().required("Campo Obrigatório").nullable(),

    admi_salario: Yup.string().required("Campo Obrigatório").nullable(),

    admi_banco: Yup.string().required("Campo Obrigatório").nullable(),

    admi_agencia: Yup.string().required("Campo Obrigatório").nullable(),

    admi_conta: Yup.string().required("Campo Obrigatório").nullable(),

    //Finalização
    admi_experiencia_p1: Yup.string().required("Campo Obrigatório").nullable(),

    admi_experiencia_p2: Yup.string().required("Campo Obrigatório").nullable(),

    admi_sexo: Yup.string().required("Campo Obrigatório").nullable(),

  });

  function ExibirAlerta(titulo, mensagem) {
    setTelaMsg(mensagem);
    setTelaTitulo(titulo);
    setTelaAlerta(true);
  }

  function FormataCPF(CPF) {
    return formatToCPF(CPF);
  }

  function FormataData(data) {
    return formatToDate(data);
  }

  function transformarNomeArquivo(nomeArquivo) {
    return nomeArquivo.replace(/[^\w\s\.]/gi, '').replace(/\s+/g, '_').toLowerCase();
  }

  function buscaEndereco(ev, setFieldValue) {
    const { value } = ev.target;
    console.log("Iniando Busca por CEP " + value);
    var cep = value.replace(/\D/g, "");
    console.log("CEP Recebido: " + cep);
    if (cep !== "") {
      var validaCep = /^[0-9]{8}$/;
      if (validaCep.test(cep)) {
        setMsgLoading("Buscando Endereço");
        setLoading(true);
        console.log("Buscando CEP: " + `https://viacep.com.br/ws/${cep}/json/`);

        fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((res) => res.json())
          .then((data) => {
            setLoading(false);
            console.log("achou " + data.logradouro);
            // campoNumero.current.focus()
            setFieldValue("admi_end_endereco", data.logradouro);
            setFieldValue("admi_end_bairro", data.bairro);
            setFieldValue("admi_end_cidade", data.localidade);
            setFieldValue("admi_end_uf", data.uf);
          });
      }
    }
  }

  async function getAdmissao(id) {
    setMsgLoading("Obtendo Admissão");
    setLoading(true);

    setValoresIniciais({});

    function contemApenasNumeros(str) {
      return /^[0-9]+$/.test(str);
    }

    let obj = {}
    let viewTicket = false

    if (contemApenasNumeros(id)) {
      obj = {
        ticket: id
      }
      viewTicket = true
      setViewTicket(true)
    } else {
      obj = {
        identificacao: base64_decode(id)
      }
    }

    axios
      .post(
        conexao.endereco + "/admissoes/individual",
        obj,
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(async function (response) {
        console.log("Dados admissão: ", response.data);

        setLoading(false);

        if (response.data.admi_status === "F" && !viewTicket) {
          ExibirAlerta("Parabéns", "Admissão Enviada Com Sucesso!");

          return;
        } else {
          setValoresIniciais({
            ...response.data,
            admi_empresa_estab:
              response.data.admi_empresa + "|" + response.data.admi_estab,
          });
          getFilhos();
          getJornadas();
        }
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function inserirFilho(values) {

    if (!isCPF(values.cpf_filho)) {
      ExibirAlerta("Atenção", "CPF Inválido");
      return;
    }

    if (calculaIdade(values.data_nascimento_filho) > 21) {
      ExibirAlerta("Atenção", "Dependente com mais de 21 anos");
      return;
    }

    setMsgLoading("Inserindo Dependente");
    setLoading(true);

    setTelaNovoFilho(false);

    axios
      .post(
        conexao.endereco + "/admissoes/filhos/incluir",
        {
          id: base64_decode(id),
          nome: values.nome_filho,
          cpf: values.cpf_filho,
          nascimento: values.data_nascimento_filho,
          naturalidade: values.naturalidade,
          anexos: listaAnexos,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        getFilhos(base64_decode(id));
        setListaAnexos([]);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function inserirJornada() {
    if (!jornadaDias) {
      ExibirAlerta("Atenção", "Informe os Dias da Semana");
      return;
    }
    if (!jornadaHorarios) {
      ExibirAlerta("Atenção", "Informe os Horários");
      return;
    }

    setMsgLoading("Inserindo Jornada");
    setLoading(true);

    setTelaNovoFilho(false);

    axios
      .post(
        conexao.endereco + "/admissoes/jornadas/incluir",
        {
          id: base64_decode(id),
          dias: jornadaDias,
          horarios: jornadaHorarios,
          observacoes: jornadaObs,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        getJornadas(base64_decode(id));
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function inserirDocumento(link, documento) {
    setMsgLoading("Inserindo Documento");
    setLoading(true);

    axios
      .post(
        conexao.endereco + "/admissoes/documentos/incluir",
        {
          id: base64_decode(id),
          documento: documento,
          url: link,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        window.location.reload();
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function enviarDocumento(arquivo, documento) {
    setMsgLoading("Enviando Documento");
    setLoading(true);

    const formData = new FormData();

    formData.append("arquivo", arquivo);

    axios
      .post(conexao.endereco + "/arquivos/enviar", formData, {
        headers: { token: localStorage.getItem("Token") },
      })

      .then(function (response) {
        inserirDocumento(response.data.url, documento);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function UploadArquivo(values) {

    setMsgLoading(`Enviando Arquivo ${values.name}`)
    setLoading(true)

    const formData = new FormData()
    formData.append('arquivo', values)

    await axios.post(conexao.endereco + '/arquivos/enviar',
      formData, { headers: { token: localStorage.getItem('Token') } })

      .then(function (response) {
        setLoading(false)

        let obj = {
          nome: transformarNomeArquivo(values.name),
          url: response.data.url
        }

        listaAnexos.push(obj)
        console.log(response.data.url)
        return response.data.url

      })
      .catch(function (error) {
        console.error(error)
        setLoading(false)
        return null
      })
  }

  const enviarSolicitacao = async () => {
    setMsgLoading("Enviando solicitação");
    setLoading(true);

    // const id_empresa = idEmpresaSelecionada
    const id_operador = "0";
    const id_usuario = localStorage.getItem("id_usuario");
    const id_prioridade = "3";
    const id_categoria = "16";

    const admissao_id_decoded = base64_encode(valoresIniciais.admi_id);

    const assunto = `Admissão: ${valoresIniciais.admi_nome}`;

    const conteudo = `finalizada a contratação do funcionário ${valoresIniciais.admi_nome}. \n 
        confira os detalhes da admissão no anexo`;

    try {
      const response = await axios.post(
        conexao.endereco + "/solicitacoes/incluir",
        {
          empresa: idEmpresaSelecionada,
          estabelecimento: localStorage.getItem("estabelecimento"),
          usuario: id_usuario,
          operador: id_operador,
          categoria: id_categoria,
          prioridade: id_prioridade,
          assunto: assunto,
          tipo: "ADMISSAO",
          funcionario: valoresIniciais.admi_nome,
          conteudo: conteudo,
          anexo: `/departamento-pessoal/admissoes/${admissao_id_decoded}`,
        },
        { headers: { token: localStorage.getItem("Token") } }
      );

      setLoading(false);
      console.log('ID Ticket Resposta:', response.data.ticket);
      if (response.status === 201) {
        return response.data.ticket;
      } else {
        return null
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      return null
    }

  };

  async function getFilhos() {
    setMsgLoading("Listando Filhos");
    setLoading(true);

    setTelaNovoFilho(false);

    axios
      .post(
        conexao.endereco + "/admissoes/filhos/listar",
        {
          id: base64_decode(id),
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        setListaFilhos(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function getJornadas() {
    setMsgLoading("Listando Jornadas de Trabalho");
    setLoading(true);

    setTelaNovaJornada(false);

    axios
      .post(
        conexao.endereco + "/admissoes/jornadas/listar",
        {
          id: base64_decode(id),
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        setListaJornadas(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function ExcluirFilho(identificacao, nomeArquivo) {
    setMsgLoading("Excluindo Filho");
    setLoading(true);

    if (nomeArquivo) {
      ExcluirArquivo(nomeArquivo);
    }

    axios
      .post(
        conexao.endereco + "/admissoes/filhos/excluir",
        {
          id: identificacao,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        getFilhos(base64_decode(id));
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function ExcluirJornada(identificacao) {
    setMsgLoading("Excluindo Jornada");
    setLoading(true);

    axios
      .post(
        conexao.endereco + "/admissoes/jornadas/excluir",
        {
          id: identificacao,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        getJornadas(base64_decode(id));
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function ExcluirDocumento(identificacao, documento, nomeArquivo) {
    setMsgLoading("Excluindo Documento");
    setLoading(true);

    if (nomeArquivo) {
      const arquivo = nomeArquivo.split("/");
      ExcluirArquivo(arquivo[3]);
    }

    axios
      .post(
        conexao.endereco + "/admissoes/documentos/excluir",
        {
          id: identificacao,
          documento: documento,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        window.location.reload();
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  async function ExcluirArquivo(nomeArquivo) {
    setMsgLoading("Excluindo Arquivo");
    setLoading(true);

    axios
      .post(
        conexao.endereco + "/arquivos/remover",
        {
          arquivo: nomeArquivo,
        },
        { headers: { token: localStorage.getItem("Token") } }
      )

      .then(function (response) {
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  }

  function verificaJornadas(values) {
    const textosJornadas = [];

    textosJornadas.push(values.admi_jornada_trab_seg);
    textosJornadas.push(values.admi_jornada_trab_tec);
    textosJornadas.push(values.admi_jornada_trab_qua);
    textosJornadas.push(values.admi_jornada_trab_qui);
    textosJornadas.push(values.admi_jornada_trab_sex);
    textosJornadas.push(values.admi_jornada_trab_sab);
    textosJornadas.push(values.admi_jornada_trab_dom);

    const arrayDSRs = textosJornadas.filter((texto) => texto === "DSR");

    if (arrayDSRs.length !== 1) {
      throw new Error("Jornada deve ter 1 DSR");
    }
  }

  async function atualizarAdmissao(values, finalizado, tipoBotao) {
    setMsgLoading("Atualizando Admissão");
    console.log("Values:", values);

    try {
      if (tipoBotao === "finaliza") {
        verificaJornadas(values);
      }

      let idTicket = ''

      setLoading(true);

      if (finalizado) {
        idTicket = await enviarSolicitacao();

        if (!idTicket) {
          setLoading(false)
          alert("Não foi possível enviar a solicitação, por favor tente novamente ou entre em contato com o suporte")
          return;
        }
        console.log('idTicket Obtido: ', idTicket);
      }

      await axios.post(
        conexao.endereco + "/admissoes/atualizar",
        {
          ...values,
          admi_end_cep: values.admi_end_cep ? values.admi_end_cep.replace(/\D/g, "") : null,
          admi_status: finalizado ? "F" : "P",
          admi_empresa: values.admi_empresa_estab.split("|")[0],
          admi_estab: values.admi_empresa_estab.split("|")[1],
          ticket: idTicket,
          admi_adicionais_insalubridade: values.admi_adicionais_insalubridade
            ? values.admi_adicionais_insalubridade_select
            : null,
        },
        {
          headers: { token: localStorage.getItem("Token") },
        }
      );

      getAdmissao(values.admi_id);
      setLoading(false);
    } catch (error) {
      console.error(error);
      alert(error.message);
      setLoading(false);
    }
  }

  function calculaIdade(dataNasc) {
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNasc.split("-");
    var diaNasc = anoNascParts[2];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[0];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;
    if (mesAtual < mesNasc) {
      idade--;
    } else {
      //Se estiver no mes do nascimento, verificar o dia
      if (mesAtual == mesNasc) {
        if (new Date().getDate() < diaNasc) {
          //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
          idade--;
        }
      }
    }
    return idade;
  }

  function ListaFilhosFuncionario() {
    const colunas = [
      {
        name: "Nome do Dependente",
        selector: (row) => row.adfi_nome,
        sortable: true,
        minWidth: "30%",
      },
      {
        name: "CPF",
        selector: (row) => row.adfi_cpf,
        format: (row) => formatToCPF(row.adfi_cpf),
        sortable: true,
        minWidth: "40%",
      },
      {
        name: "Data de Nascimento",
        selector: (row) => row.adfi_data_nascimento,
        sortable: true,
        wrap: true,
      },
      {
        name: "Documentos",
        button: true,
        omit: true,
        cell: (row) => (
          <Hint
            posicao={"top"}
            id={"certidao_filho"}
            texto={
              row.adfi_certidao
                ? "Download dos Documentos"
                : "Documentos Não Enviados"
            }
          >
            <a
              onClick={() => {
                row.adfi_certidao
                  ? window.open(row.adfi_certidao)
                  : ExibirAlerta("Atenção", "Arquivos Não Localizados!");
              }}
            >
              {row.adfi_certidao && <MdFileDownload size="24" color="green" />}
              {!row.adfi_certidao && <MdError size="24" color="red" />}
            </a>
          </Hint>
        ),
      },
      {
        name: "Excluir",
        button: true,
        omit: viewTicket,
        cell: (row) => (
          <Hint posicao={"top"} id={"excluir_filho"} texto={"Excluir Filho"}>
            <a
              onClick={() => {
                ExcluirFilho(row.adfi_id, row.nome_arquivo);
              }}
            >
              <TiDelete size="24" color="#E30613" />
            </a>
          </Hint>
        ),
      },
    ];

    const paginationComponentOptions = {
      rowsPerPageText: "Registros por página",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos",
    };

    return (
      <DataTable
        columns={colunas}
        data={listaFilhos}
        striped={true}
        highlightOnHover={true}
        responsive={true}
        noDataComponent={"Nenhum Dependente Informado =/"}
        pagination={true}
        paginationComponentOptions={paginationComponentOptions}
      />
    );
  }

  async function quandoClicarDia(dia, input, descricao) {
    setDiaSelecionadoJornada(dia);
    setDiaDescricao(descricao);
    setInputDiaAtual(input);
    setEstaAbertoModalEscalas(true);
  }

  function ListaJornadas() {
    const colunas = [
      {
        name: "Dias da Semana",
        selector: (row) => row.adjo_dias_semana,
        sortable: true,
        minWidth: "30%",
        wrap: true,
      },
      {
        name: "Horários",
        selector: (row) => row.adjo_horarios,
        sortable: true,
        minWidth: "30%",
      },
      {
        name: "Observações",
        selector: (row) => row.adjo_observacoes,
        sortable: true,
        minWidth: "30%",
        wrap: true,
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Hint
            posicao={"top"}
            id={"excluir_jornada"}
            texto={"Excluir Jornada"}
          >
            <a
              onClick={() => {
                ExcluirJornada(row.adjo_id);
              }}
            >
              <TiDelete size="24" color="#E30613" />
            </a>
          </Hint>
        ),
      },
    ];

    const paginationComponentOptions = {
      rowsPerPageText: "Registros por página",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos",
    };

    return (
      <DataTable
        columns={colunas}
        data={listaJornadas}
        striped={true}
        highlightOnHover={true}
        responsive={true}
        noDataComponent={"Nenhuma Jornada Informada =/"}
        pagination={true}
        paginationComponentOptions={paginationComponentOptions}
      />
    );
  }

  useLayoutEffect(() => {
    getAdmissao(id);
    setTabAtiva(localStorage.getItem("tab"));
  }, []);

  useEffect(() => {
    localStorage.setItem("tab", tabAtiva);
  }, [tabAtiva]);

  return (
    <>
      <Navegacao />

      <div
        style={{
          display: "flex",
          backgroundColor: "#f5f5f5",
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          style={{
            display: "flex",
            backgroundColor: "#f5f5f5",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Col
            sm={12}
            lg={12}
            style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}
          >
            <Row>
              <Col
                sm={12}
                lg={7}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <h3>Admissão de Colaborador</h3>
              </Col>
              <Col
                sm={12}
                lg={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#E30613",
                    border: 0,
                    marginRight: 15,
                  }}
                  size="sm"
                  type="button"
                  form="formAdmissao"
                  onClick={() => {
                    Navigate(-1);
                  }}
                >
                  Voltar
                </Button>
                {!viewTicket &&
                  <Button
                    style={{
                      backgroundColor: "#E30613",
                      border: 0,
                      marginRight: 15,
                    }}
                    size="sm"
                    type="button"
                    form="formAdmissao"
                    onClick={async () => {
                      formRef.current.setFieldValue("admi_status", "P");
                      atualizarAdmissao(formRef.current.values, false, "salva");
                      window.location.reload();
                    }}
                  >
                    Salvar Alterações
                  </Button>
                }
                <Button
                  style={{ backgroundColor: "#E30613", border: 0 }}
                  size="sm"
                  type="button"
                  onClick={() => {
                    alert(JSON.stringify(formRef.current.values));
                  }}
                >
                  Obter Ajuda
                </Button>
              </Col>
            </Row>
          </Col>
          <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
            <hr />
          </Row>
          <div style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Row>
              <Col>
                <Row>
                  {erroData && (
                    <Alert key="danger" variant="danger">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: 28,
                        }}
                      >
                        <strong>{erroMsg}</strong>
                      </div>
                    </Alert>
                  )}
                  {console.log("EMPRESAS DO USUÁRIO", empresasUsuarioTemAcesso)}
                  {console.log("EMPRESA SELECIONADA", empresaSelecionada)}
                </Row>
                {console.log(
                  "ID EMPRESA SELECIONADA ====>",
                  idEmpresaSelecionada
                )}
                <Row style={{ marginBottom: 60 }}>
                  <Formik

                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                      console.log("Dados para Atualizacao", values);
                      atualizarAdmissao(values, true, "finaliza");
                      // window.location.reload()
                    }}
                    initialValues={valoresIniciais}
                    validationSchema={SchemaAdmissao}
                    innerRef={formRef}
                    render={({
                      values,
                      errors,
                      touched,
                      isValid,
                      setFieldValue,
                    }) => (
                      <>
                        <Form autoComplete="off" id="formAdmissao">
                          <Alert key="warning" variant="warning">
                            <div>
                              <SelectFieldOnChange
                                texto="A admissão está sendo feita para a empresa"
                                campo="admi_empresa_estab"
                                disabled={viewTicket}
                                mudar={(valor) => {
                                  setFieldValue(
                                    "admi_empresa_estab",
                                    valor.target.value
                                  );
                                  setFieldValue("admi_cargo", "");

                                  setFieldValue("admi_jornada_trab_seg", "");
                                  setFieldValue("admi_jornada_trab_ter", "");
                                  setFieldValue("admi_jornada_trab_qua", "");
                                  setFieldValue("admi_jornada_trab_qui", "");
                                  setFieldValue("admi_jornada_trab_sex", "");
                                  setFieldValue("admi_jornada_trab_sab", "");
                                  setFieldValue("admi_jornada_trab_dom", "");
                                }}
                              >
                                <option
                                  value=""
                                  selected
                                  disabled
                                  hidden
                                ></option>
                                {empresasUsuarioTemAcesso.map((empresa) => (
                                  <option
                                    value={
                                      empresa.empr_questor_empresa +
                                      "|" +
                                      empresa.empr_questor_estabelecimento
                                    }
                                  >
                                    {empresa.empr_razao} - Empresa:
                                    {empresa.empr_questor_empresa} - Estab:{" "}
                                    {empresa.empr_questor_estabelecimento} - CNPJ: {" "} {empresa.empr_cnpj}
                                  </option>
                                ))}
                              </SelectFieldOnChange>
                            </div>
                          </Alert>
                          <ModalSalario
                            isOpen={isOpenModalSalario}
                            setIsOpen={setIsOpenModalSalario}
                            setCriarCargo={setCriarCargo}
                            setFieldValue={setFieldValue}
                            empresa={
                              formRef?.current?.values?.admi_empresa_estab?.split(
                                "|"
                              )[0]
                            }
                          />

                          {estaAbertoModalJornadas && (
                            <ModalJornadas
                              estaAberto={estaAbertoModalJornadas}
                              setEstaAberto={setEstaAbertoModalEscalas}
                              dia={diaSelecionadoJornada}
                              descricao={diaDescricao}
                              campo={inputDiaAtual}
                              setFieldValue={setFieldValue}
                              empresa={
                                formRef?.current?.values?.admi_empresa_estab?.split(
                                  "|"
                                )[0]
                              }
                            />
                          )}

                          <Tabs
                            onSelect={(tab) => setTabAtiva(tab)}
                            activeKey={tabAtiva}
                            id="tabela-admissao"
                            className="mb-3"
                            fill
                            variant="tabs"
                            justify
                          >
                            <Tab
                              eventKey="dados-pessoais"
                              title="Dados Pessoais"
                              o
                            >
                              <Row>
                                <Col>
                                  <Row>
                                    <Col sm={12} lg={6} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_nome"
                                        tipo="text"
                                        texto="Nome do Empregado"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_nome" />
                                    </Col>
                                    <Col sm={12} lg={2} className="mb-3">
                                      <SelectFieldPadrao
                                        texto="Sexo"
                                        campo="admi_sexo"
                                        disabled={viewTicket}
                                      >
                                        <option
                                          value=""
                                          selected
                                          disabled
                                          hidden
                                        >
                                          {" "}
                                          -- Selecione
                                        </option>
                                        {SEXO.map((item) => (
                                          <option value={item}>
                                            {" "}
                                            {item}
                                          </option>
                                        ))}
                                      </SelectFieldPadrao>
                                      <ErroField campo="admi_sexo" />
                                    </Col>
                                    <Col sm={12} lg={2} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_data_nascimento"
                                        tipo="date"
                                        texto="Data de Nascimento"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_data_nascimento" />
                                    </Col>
                                    <Col sm={12} lg={2} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_data_admissao"
                                        tipo="date"
                                        texto="Data de Admissão"
                                        disabled={viewTicket}
                                        blur={() => {
                                          if (values.admi_data_admissao) {
                                            const dt1 = new Date(
                                              values.admi_data_admissao
                                            );
                                            const dtHoje = new Date();

                                            const admissao = new Date(
                                              dt1.getFullYear(),
                                              dt1.getMonth(),
                                              dt1.getDate() + 1
                                            );
                                            const dataAtual = new Date(
                                              dtHoje.getFullYear(),
                                              dtHoje.getMonth(),
                                              dtHoje.getDate()
                                            );

                                            console.log("Admissao", admissao);
                                            console.log(
                                              "Data Atual",
                                              dataAtual
                                            );

                                            if (
                                              admissao.getTime() <
                                              dataAtual.getTime()
                                            ) {
                                              setErroMsg(
                                                "Fora do prazo para execução, sujeito a multa"
                                              );
                                              setErroData(true);
                                            } else if (
                                              admissao.getTime() ==
                                              dataAtual.getTime()
                                            ) {
                                              setErroMsg(
                                                "Fora do prazo de envio do eSocial, sujeito a multa"
                                              );
                                              setErroData(true);
                                            } else {
                                              setErroData(false);
                                            }
                                          }
                                        }}
                                      />
                                      <ErroField campo="admi_data_admissao" />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12} lg={6} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_naturalidade"
                                        tipo="text"
                                        texto="Naturalidade"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_naturalidade" />
                                    </Col>
                                    <Col sm={12} lg={3} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_cpf"
                                        tipo="text"
                                        texto="CPF"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_cpf" />
                                    </Col>
                                    <Col sm={12} lg={3} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_pis"
                                        tipo="text"
                                        texto="Nº do PIS"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_pis" />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12} lg={6} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_nome_mae"
                                        tipo="text"
                                        texto="Nome da Mãe"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_nome_mae" />
                                    </Col>
                                    <Col sm={12} lg={6} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_nome_pai"
                                        tipo="text"
                                        texto="Nome do Pai"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_nome_pai" />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12} lg={4} className="mb-3">
                                      <InputFieldTelefone
                                        campo="admi_telefone"
                                        disabled={viewTicket}
                                        mudar={(valor) => {
                                          setFieldValue(
                                            "admi_telefone",
                                            formatToPhone(valor.target.value)
                                          );
                                        }}
                                        tipo="text"
                                        texto="Telefone"
                                      />
                                      <ErroField campo="admi_telefone" />
                                    </Col>
                                    <Col sm={12} lg={8} className="mb-3">
                                      <InputFieldPadrao
                                        disabled={viewTicket}
                                        campo="admi_email"
                                        tipo="email"
                                        texto="E-mail"
                                      />
                                      <ErroField campo="admi_email" />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12} lg={5} className="mb-3">
                                      <SelectFieldPadrao
                                        texto="Escolaridade"
                                        disabled={viewTicket}
                                        campo="admi_escolaridade"
                                      >
                                        <option
                                          value=""
                                          selected
                                          disabled
                                          hidden
                                        >
                                          {" "}
                                          -- Selecione
                                        </option>
                                        {ESCOLARIDADE.map((item) => (
                                          <option value={item}>
                                            {" "}
                                            {item}
                                          </option>
                                        ))}
                                      </SelectFieldPadrao>
                                      <ErroField campo="admi_escolaridade" />
                                    </Col>
                                    <Col sm={12} lg={5} className="mb-3">
                                      <SelectFieldPadrao
                                        texto="Estado Civil"
                                        disabled={viewTicket}
                                        campo="admi_estadocivil"
                                      >
                                        <option
                                          value=""
                                          selected
                                          disabled
                                          hidden
                                        >
                                          {" "}
                                          -- Selecione
                                        </option>
                                        {ESTADO_CIVIL.map((item) => (
                                          <option value={item}>
                                            {" "}
                                            {item}
                                          </option>
                                        ))}
                                      </SelectFieldPadrao>
                                      <ErroField campo="admi_estadocivil" />
                                    </Col>
                                    <Col sm={12} lg={2} className="mb-3">
                                      <SelectFieldPadrao
                                        texto="Raça / Cor"
                                        campo="admi_raca_cor"
                                        disabled={viewTicket}
                                      >
                                        <option value="" selected disabled hidden>
                                          {" "}
                                          -- Selecione
                                        </option>
                                        {RACA_COR.map((item) => (
                                          <option value={item}>{" "}{item}</option>
                                        ))}
                                      </SelectFieldPadrao>
                                      <ErroField campo="admi_raca_cor" />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Tab>
                            <Tab eventKey="endereco" title="Endereço">
                              <Row>
                                <Col>
                                  <Row>
                                    <Col sm={12} lg={2} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_end_cep"
                                        tipo="text"
                                        disabled={viewTicket}
                                        texto="CEP"
                                        blur={(ev) =>
                                          buscaEndereco(ev, setFieldValue)
                                        }
                                      />
                                      <ErroField campo="admi_end_cep" />
                                    </Col>
                                    <Col sm={12} lg={8} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_end_endereco"
                                        tipo="text"
                                        texto="Endereço"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_end_endereco" />
                                    </Col>
                                    <Col sm={12} lg={2} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_end_numero"
                                        tipo="text"
                                        texto="Nº"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_end_numero" />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12} lg={3} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_end_bairro"
                                        tipo="text"
                                        disabled={viewTicket}
                                        texto="Bairro"
                                      />
                                      <ErroField campo="admi_end_bairro" />
                                    </Col>
                                    <Col sm={12} lg={8} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_end_cidade"
                                        tipo="text"
                                        texto="Cidade"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_end_cidade" />
                                    </Col>
                                    <Col sm={12} lg={1} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_end_uf"
                                        tipo="text"
                                        texto="UF"
                                        disabled={viewTicket}
                                      />
                                      <ErroField campo="admi_end_uf" />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12} lg={12} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_end_complemento"
                                        tipo="text"
                                        disabled={viewTicket}
                                        texto="Complemento"
                                      />
                                      <ErroField campo="admi_end_complemento" />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Tab>
                            <Tab eventKey="adicionais" title="Adicionais">
                              <Row>
                                <Col>
                                  <Row>
                                    <Col sm={12} lg={8} className="mb-3">
                                      {/* <InputFieldPadrao campo="admi_cargo" tipo="text" texto="Cargo / Função" /> */}

                                      <div
                                        style={{
                                          display: "flex",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <label
                                          htmlFor="admi_cargo"
                                          className="form-label me-3"
                                        >
                                          Cargo / Função
                                        </label>
                                        {!viewTicket &&
                                          <Hint
                                            posicao={"top"}
                                            id={"buscar_cargos"}
                                            texto={"Buscar Cargos"}
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setCriarCargo(false);
                                                setIsOpenModalSalario(true);
                                              }}
                                            >
                                              <BsSearch
                                                style={{
                                                  fontSize: 16,
                                                  color: "#E30613",
                                                }}
                                              />
                                            </div>
                                          </Hint>
                                        }
                                      </div>

                                      <Field
                                        id="admi_cargo"
                                        name="admi_cargo"
                                        disabled={criarCargo ? false : true}
                                        type="text"
                                        className="form-control"
                                      />
                                      <ErroField campo="admi_cargo" />
                                    </Col>

                                    <Col sm={12} lg={4} className="mb-3">
                                      <SelectFieldPadrao
                                        texto="Insalubridade"
                                        disabled={viewTicket}
                                        campo="admi_adicionais_insalubridade"
                                      >
                                        <option>Não</option>
                                        <option>10%</option>
                                        <option>20%</option>
                                        <option>40%</option>
                                      </SelectFieldPadrao>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm={12} lg={6} className="mb-3">
                                      <SelectFieldPadrao
                                        texto="Banco"
                                        campo="admi_banco"
                                        disabled={viewTicket}
                                      >
                                        <option
                                          value=""
                                          selected
                                          disabled
                                          hidden
                                        >
                                          {" "}
                                          -- Selecione o Banco
                                        </option>
                                        {planilhaBancos.map((banco) => (
                                          <option value={banco.codBanco}>
                                            {" "}
                                            {banco.codBanco} - {banco.nomeBanco}{" "}
                                          </option>
                                        ))}
                                      </SelectFieldPadrao>
                                      <ErroField campo="admi_banco" />
                                    </Col>

                                    <Col sm={12} lg={2} className="mb-3">
                                      <label
                                        htmlFor="admi_agencia"
                                        className="form-label"
                                      >
                                        Agência
                                      </label>
                                      <Field
                                        id="admi_agencia"
                                        name="admi_agencia"
                                        disabled={viewTicket}
                                        type="text"
                                        className="form-control"
                                        style={{ marginRight: "1rem" }}
                                      />
                                      <ErroField campo="admi_agencia" />
                                    </Col>

                                    <Col sm={12} lg={2} className="mb-3">
                                      <label
                                        htmlFor="admi_conta"
                                        className="form-label"
                                      >
                                        Conta
                                      </label>
                                      <Field
                                        id="admi_conta"
                                        disabled={viewTicket}
                                        name="admi_conta"
                                        type="text"
                                        className="form-control"
                                        style={{ marginRight: "1rem" }}
                                      />
                                      <ErroField campo="admi_conta" />
                                    </Col>

                                    <Col sm={12} lg={2} className="mb-3">
                                      <label
                                        htmlFor="admi_salario"
                                        className="form-label"
                                      >
                                        Salário
                                      </label>
                                      <Field
                                        id="admi_salario"
                                        name="admi_salario"
                                        disabled={viewTicket}
                                        type="text"
                                        className="form-control"
                                        style={{ marginRight: "1rem" }}
                                      />
                                      <ErroField campo="admi_salario" />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm={12} lg={10} className="mb-3">
                                      <label
                                        htmlFor="admi_centro_custo"
                                        className="form-label"
                                      >
                                        Centro de Custo
                                      </label>
                                      <Field
                                        id="admi_centro_custo"
                                        name="admi_centro_custo"
                                        type="text"
                                        disabled={viewTicket}
                                        className="form-control"
                                        style={{ marginRight: "1rem" }}
                                      />
                                    </Col>

                                    <Col sm={12} lg={2} className="mb-3">
                                      <SelectFieldPadrao
                                        texto="Holerite por email"
                                        disabled={viewTicket}
                                        campo="admi_holerite_email"
                                      >
                                        <option>Não</option>
                                        <option>Sim</option>
                                      </SelectFieldPadrao>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col
                                      sm={12}
                                      lg={3}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      className="mb-3"
                                    >
                                      <CheckFieldPadrao
                                        campo="admi_adicionais_40"
                                        tipo="checkbox"
                                        disabled={viewTicket}
                                      >
                                        Adicional 40%
                                      </CheckFieldPadrao>
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={3}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      className="mb-3"
                                    >
                                      <CheckFieldPadrao
                                        campo="admi_adicionais_quebra_caixa"
                                        tipo="checkbox"
                                        disabled={viewTicket}
                                      >
                                        Quebra de Caixa
                                      </CheckFieldPadrao>
                                    </Col>

                                    <Col
                                      sm={12}
                                      lg={3}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      className="mb-3"
                                    >
                                      <CheckFieldPadrao
                                        campo="admi_adicionais_periculosidade"
                                        tipo="checkbox"
                                        disabled={viewTicket}
                                      >
                                        Periculosidade
                                      </CheckFieldPadrao>
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={3}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      className="mb-3"
                                    >
                                      <CheckFieldPadrao
                                        campo="admi_adicionais_vale_transporte"
                                        tipo="checkbox"
                                        disabled={viewTicket}
                                      >
                                        Vale Transporte
                                      </CheckFieldPadrao>
                                    </Col>

                                    {/* <Col sm={12} lg={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="mb-3">
                                                                            <CheckFieldPadrao campo="admi_adicionais_insalubridade_10" tipo="checkbox" >
                                                                                Insalubridade 10%
                                                                            </CheckFieldPadrao>
                                                                        </Col>
                                                                        <Col sm={12} lg={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="mb-3">
                                                                            <CheckFieldPadrao campo="admi_adicionais_insalubridade_20" tipo="checkbox" >
                                                                                Insalubridade 20%
                                                                            </CheckFieldPadrao>
                                                                        </Col>
                                                                        <Col sm={12} lg={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="mb-3">
                                                                            <CheckFieldPadrao campo="admi_adicionais_insalubridade_40" tipo="checkbox" >
                                                                                Insalubridade 40%
                                                                            </CheckFieldPadrao>
                                                                        </Col> */}
                                  </Row>
                                </Col>
                              </Row>
                            </Tab>
                            <Tab eventKey="jornada" title="Jornada">
                              <Row style={{ marginBottom: 70 }}>
                                <Col>
                                  <Row
                                    style={{ marginTop: 20, marginBottom: 0 }}
                                  >
                                    <Col className="mb-3" lg={6}>
                                      <InputLegenda
                                        descricao="Segunda-Feira"
                                        campo="admi_jornada_trab_seg"
                                        disabled={viewTicket}
                                        onClick={() =>
                                          quandoClicarDia(
                                            "SEG",
                                            "admi_jornada_trab_seg",
                                            "Segunda-Feira"
                                          )
                                        }
                                      />
                                      <ErroField campo="admi_jornada_trab_seg" />
                                    </Col>
                                    <Col className="mb-3" lg={6}>
                                      <InputLegenda
                                        className="mb-0"
                                        descricao="Terça-Feira"
                                        campo="admi_jornada_trab_ter"
                                        disabled={viewTicket}
                                        onClick={() =>
                                          quandoClicarDia(
                                            "TER",
                                            "admi_jornada_trab_ter",
                                            "Terça-Feira"
                                          )
                                        }
                                      />
                                      <ErroField campo="admi_jornada_trab_ter" />
                                    </Col>
                                  </Row>
                                  <Row
                                    style={{ marginTop: 20, marginBottom: 20 }}
                                  >
                                    <Col className="mb-3" lg={6}>
                                      <InputLegenda
                                        className="mb-0"
                                        descricao="Quarta-Feira"
                                        disabled={viewTicket}
                                        campo="admi_jornada_trab_qua"
                                        onClick={() =>
                                          quandoClicarDia(
                                            "QUA",
                                            "admi_jornada_trab_qua",
                                            "Quarta-Feira"
                                          )
                                        }
                                      />
                                      <ErroField campo="admi_jornada_trab_qua" />
                                    </Col>
                                    <Col className="mb-3" lg={6}>
                                      <InputLegenda
                                        className="mb-0"
                                        descricao="Quinta-Feira"
                                        disabled={viewTicket}
                                        campo="admi_jornada_trab_qui"
                                        onClick={() =>
                                          quandoClicarDia(
                                            "QUI",
                                            "admi_jornada_trab_qui",
                                            "Quinta-Feira"
                                          )
                                        }
                                      />
                                      <ErroField campo="admi_jornada_trab_qui" />
                                    </Col>
                                  </Row>
                                  <Row
                                    style={{ marginTop: 20, marginBottom: 20 }}
                                  >
                                    <Col className="mb-3" lg={6}>
                                      <InputLegenda
                                        className="mb-0"
                                        descricao="Sexta-Feira"
                                        disabled={viewTicket}
                                        campo="admi_jornada_trab_sex"
                                        onClick={() =>
                                          quandoClicarDia(
                                            "SEX",
                                            "admi_jornada_trab_sex",
                                            "Sexta-Feira"
                                          )
                                        }
                                      />
                                      <ErroField campo="admi_jornada_trab_sex" />
                                    </Col>
                                    <Col className="mb-3" lg={6}>
                                      <InputLegenda
                                        className="mb-0"
                                        descricao="Sábado"
                                        disabled={viewTicket}
                                        campo="admi_jornada_trab_sab"
                                        onClick={() =>
                                          quandoClicarDia(
                                            "SAB",
                                            "admi_jornada_trab_sab",
                                            "Sábado"
                                          )
                                        }
                                      />
                                      <ErroField campo="admi_jornada_trab_sab" />
                                    </Col>
                                  </Row>
                                  <Row
                                    style={{ marginTop: 20, marginBottom: 20 }}
                                  >
                                    <Col className="mb-3" lg={6}>
                                      <InputLegenda
                                        className="mb-0"
                                        descricao="Domingo"
                                        disabled={viewTicket}
                                        campo="admi_jornada_trab_dom"
                                        onClick={() =>
                                          quandoClicarDia(
                                            "DOM",
                                            "admi_jornada_trab_dom",
                                            "Domingo"
                                          )
                                        }
                                      />
                                      <ErroField campo="admi_jornada_trab_dom" />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              {/* <Row>
                                                                <Col sm={12} lg={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5 }}>
                                                                    <Row style={{ paddingLeft: 30 }}>
                                                                        Jornadas de Trabalho
                                                                    </Row>
                                                                    <Button
                                                                        style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                                                        size='sm'
                                                                        onClick={() => {
                                                                            setJornadaDias(null)
                                                                            setJornadaHorarios(null)
                                                                            setJornadaObs(null)
                                                                            setTelaNovaJornada(true)
                                                                            setCriarEscala(false)
                                                                        }}
                                                                    >
                                                                        Adicionar Jornada
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: 15 }}>
                                                                <Col>
                                                                    <ListaJornadas />
                                                                </Col>
                                                            </Row> */}
                            </Tab>
                            <Tab eventKey="documentacao" title="Documentação">
                              <Row style={{ marginBottom: 50 }}>
                                <Col>
                                  <Row>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <DocumentoField
                                        nome="Foto 3x4"
                                        disabled={viewTicket}
                                        campo="admi_dcto_foto3x4"
                                        status={
                                          values.admi_dcto_foto3x4
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_foto3x4,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_foto3x4",
                                            values.admi_dcto_foto3x4
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_foto3x4"
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <DocumentoField
                                        nome="Carteira de Trabalho (CTPS)"
                                        campo="admi_dcto_ctps"
                                        status={
                                          values.admi_dcto_ctps
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_ctps,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_ctps",
                                            values.admi_dcto_ctps
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_ctps"
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <DocumentoField
                                        nome="Carteira de Identidade (RG)"
                                        campo="admi_dcto_rg"
                                        status={
                                          values.admi_dcto_rg
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_rg,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_rg",
                                            values.admi_dcto_rg
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_rg"
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <DocumentoField
                                        nome="CPF"
                                        campo="admi_dcto_cpf"
                                        status={
                                          values.admi_dcto_cpf
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_cpf,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_cpf",
                                            values.admi_dcto_cpf
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_cpf"
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      {/* <DocumentoField nome="Titulo de Eleitor" /> */}
                                      <DocumentoField
                                        nome="Titulo de Eleitor"
                                        campo="admi_dcto_titulo_eleitor"
                                        status={
                                          values.admi_dcto_titulo_eleitor
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_titulo_eleitor,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_titulo_eleitor",
                                            values.admi_dcto_titulo_eleitor
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_titulo_eleitor"
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <DocumentoField
                                        nome="Carteira de Motorista (CNH)"
                                        campo="admi_dcto_cnh"
                                        status={
                                          values.admi_dcto_cnh
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_cnh,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_cnh",
                                            values.admi_dcto_cnh
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_cnh"
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <DocumentoField
                                        nome="Comprovante de Residência"
                                        campo="admi_dcto_comprovante_residencia"
                                        status={
                                          values.admi_dcto_comprovante_residencia
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_comprovante_residencia,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_comprovante_residencia",
                                            values.admi_dcto_comprovante_residencia
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_comprovante_residencia"
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <DocumentoField
                                        nome="Certificado de Reservista"
                                        campo="admi_dcto_certificado_reservista"
                                        status={
                                          values.admi_dcto_certificado_reservista
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_certificado_reservista,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_certificado_reservista",
                                            values.admi_dcto_certificado_reservista
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_certificado_reservista"
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <DocumentoField
                                        nome="Atestado Médico Admissional"
                                        campo="admi_dcto_atestado_medico_admissional"
                                        status={
                                          values.admi_dcto_atestado_medico_admissional
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_atestado_medico_admissional,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_atestado_medico_admissional",
                                            values.admi_dcto_atestado_medico_admissional
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_atestado_medico_admissional"
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      sm={12}
                                      lg={4}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: 20,
                                      }}
                                    >
                                      <DocumentoField
                                        nome="Exame Toxicológico (Motoristas)"
                                        campo="admi_dcto_exame_toxicologico"
                                        status={
                                          values.admi_dcto_exame_toxicologico
                                            ? "Enviado"
                                            : "Pendente"
                                        }
                                        clickDownload={() => {
                                          window.open(
                                            values.admi_dcto_exame_toxicologico,
                                            "_blank"
                                          );
                                        }}
                                        clickRemove={() => {
                                          ExcluirDocumento(
                                            values.admi_id,
                                            "admi_dcto_exame_toxicologico",
                                            values.admi_dcto_exame_toxicologico
                                          );
                                        }}
                                        onChange={(event) => {
                                          enviarDocumento(
                                            event.currentTarget.files[0],
                                            "admi_dcto_exame_toxicologico"
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Tab>
                            <Tab eventKey="filhos" title="Dependentes">
                              <Row>
                                <Col
                                  sm={12}
                                  lg={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 5,
                                  }}
                                >
                                  <Row style={{ paddingLeft: 30 }}>
                                    Informe apenas dependentes com até 21 anos
                                  </Row>
                                  {!viewTicket &&
                                    <Button
                                      style={{
                                        backgroundColor: "#E30613",
                                        border: 0,
                                        marginRight: 15,
                                      }}
                                      size="sm"
                                      onClick={() => {
                                        setTelaNovoFilho(true);
                                      }}
                                    >
                                      Adicionar Dependente
                                    </Button>
                                  }
                                </Col>
                              </Row>
                              <Row style={{ marginTop: 15 }}>
                                <Col>
                                  <ListaFilhosFuncionario />
                                </Col>
                              </Row>
                            </Tab>
                            <Tab eventKey="finalizacao" title="Finalização">
                              <Row>
                                <Col sm={12} lg={6}>
                                  <Row style={{ marginBottom: 20 }}>
                                    <Col>
                                      <h5>Contrato de Experiência</h5>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12} lg={6} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_experiencia_p1"
                                        tipo="number"
                                        disabled={viewTicket}
                                        texto="Primeiro Período"
                                      />
                                      <ErroField campo="admi_experiencia_p1" />
                                    </Col>
                                    <Col sm={12} lg={6} className="mb-3">
                                      <InputFieldPadrao
                                        campo="admi_experiencia_p2"
                                        tipo="number"
                                        disabled={viewTicket}
                                        texto="Segundo Período"
                                      />
                                      <ErroField campo="admi_experiencia_p2" />
                                    </Col>
                                  </Row>
                                  <Row
                                    style={{ marginBottom: 20, marginTop: 30 }}
                                  >
                                    <Col>
                                      <h5>Autorizações</h5>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      sm={12}
                                      lg={12}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                      }}
                                      className="mb-3"
                                    >
                                      <CheckFieldPadrao
                                        campo="admi_autoriz_sem_curso_capacitacao"
                                        tipo="checkbox"
                                        disabled={viewTicket}
                                      >
                                        Autorizo Admissão Sem Os Cursos de
                                        Capacitação
                                      </CheckFieldPadrao>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      sm={12}
                                      lg={12}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                      }}
                                      className="mb-3"
                                    >
                                      <CheckFieldPadrao
                                        campo="admi_autoriz_sem_exame_toxicologico"
                                        tipo="checkbox"
                                        disabled={viewTicket}
                                      >
                                        Autorizo Admissão Sem Exame Toxicologico
                                      </CheckFieldPadrao>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      sm={12}
                                      lg={12}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                      }}
                                      className="mb-3"
                                    >
                                      <CheckFieldPadrao
                                        campo="admi_autoriz_sem_exame_admissional"
                                        tipo="checkbox"
                                        disabled={viewTicket}
                                      >
                                        Autorizo Admissão Sem Exame Admissional
                                      </CheckFieldPadrao>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  sm={12}
                                  lg={6}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Row style={{ width: "75%" }}>
                                    {errors.admi_nome && (
                                      <AlertaErro
                                        nome={"Nome:"}
                                        campo={errors.admi_nome}
                                      />
                                    )}
                                    {errors.admi_data_nascimento && (
                                      <AlertaErro
                                        nome={"Data de Nascimento:"}
                                        campo={errors.admi_data_nascimento}
                                      />
                                    )}
                                    {errors.admi_data_admissao && (
                                      <AlertaErro
                                        nome={"Data de Admissão:"}
                                        campo={errors.admi_data_admissao}
                                      />
                                    )}
                                    {errors.admi_cpf && (
                                      <AlertaErro
                                        nome={"CPF:"}
                                        campo={errors.admi_cpf}
                                      />
                                    )}
                                    {errors.admi_pis && (
                                      <AlertaErro
                                        nome={"PIS:"}
                                        campo={errors.admi_pis}
                                      />
                                    )}
                                    {errors.admi_nome_mae && (
                                      <AlertaErro
                                        nome={"Nome da Mãe:"}
                                        campo={errors.admi_nome_mae}
                                      />
                                    )}
                                    {errors.admi_nome_pai && (
                                      <AlertaErro
                                        nome={"Nome do Pai:"}
                                        campo={errors.admi_nome_pai}
                                      />
                                    )}
                                    {errors.admi_end_cep && (
                                      <AlertaErro
                                        nome={"CEP:"}
                                        campo={errors.admi_end_cep}
                                      />
                                    )}
                                    {errors.admi_end_endereco && (
                                      <AlertaErro
                                        nome={"Endereço:"}
                                        campo={errors.admi_end_endereco}
                                      />
                                    )}
                                    {errors.admi_end_numero && (
                                      <AlertaErro
                                        nome={"Número:"}
                                        campo={errors.admi_end_numero}
                                      />
                                    )}
                                    {errors.admi_end_bairro && (
                                      <AlertaErro
                                        nome={"Bairro:"}
                                        campo={errors.admi_end_bairro}
                                      />
                                    )}
                                    {errors.admi_end_cidade && (
                                      <AlertaErro
                                        nome={"Cidade:"}
                                        campo={errors.admi_end_cidade}
                                      />
                                    )}
                                    {errors.admi_end_uf && (
                                      <AlertaErro
                                        nome={"UF:"}
                                        campo={errors.admi_end_uf}
                                      />
                                    )}
                                    {errors.admi_end_complemento && (
                                      <AlertaErro
                                        nome={"Complemento:"}
                                        campo={errors.admi_end_complemento}
                                      />
                                    )}
                                    {errors.admi_cargo && (
                                      <AlertaErro
                                        nome={"Cargo:"}
                                        campo={errors.admi_cargo}
                                      />
                                    )}
                                    {errors.admi_salario && (
                                      <AlertaErro
                                        nome={"Salário:"}
                                        campo={errors.admi_salario}
                                      />
                                    )}
                                    {errors.admi_banco && (
                                      <AlertaErro
                                        nome={"Banco:"}
                                        campo={errors.admi_banco}
                                      />
                                    )}
                                    {errors.admi_agencia && (
                                      <AlertaErro
                                        nome={"Agência:"}
                                        campo={errors.admi_agencia}
                                      />
                                    )}
                                    {errors.admi_conta && (
                                      <AlertaErro
                                        nome={"Conta:"}
                                        campo={errors.admi_conta}
                                      />
                                    )}
                                    {errors.admi_jornada_observacoes && (
                                      <AlertaErro
                                        nome={
                                          "Observações Sobre os Dias de Trabalho:"
                                        }
                                        campo={errors.admi_jornada_observacoes}
                                      />
                                    )}
                                    {errors.admi_jornada_entrada && (
                                      <AlertaErro
                                        nome={"Horário Entrada:"}
                                        campo={errors.admi_jornada_entrada}
                                      />
                                    )}
                                    {errors.admi_jornada_saida && (
                                      <AlertaErro
                                        nome={"Horário Saída:"}
                                        campo={errors.admi_jornada_saida}
                                      />
                                    )}
                                    {errors.admi_jornada_intervalo_inicio && (
                                      <AlertaErro
                                        nome={"Início Intervalo:"}
                                        campo={
                                          errors.admi_jornada_intervalo_inicio
                                        }
                                      />
                                    )}
                                    {errors.admi_jornada_intervalo_fim && (
                                      <AlertaErro
                                        nome={"Final Intervalo:"}
                                        campo={
                                          errors.admi_jornada_intervalo_fim
                                        }
                                      />
                                    )}
                                    {(errors.admi_jornada_trab_seg ||
                                      errors.admi_jornada_trab_ter ||
                                      errors.admi_jornada_trab_qua ||
                                      errors.admi_jornada_trab_qui ||
                                      errors.admi_jornada_trab_sex ||
                                      errors.admi_jornada_trab_sab ||
                                      errors.admi_jornada_trab_dom) && (
                                        <AlertaErro
                                          nome={"Jornada:"}
                                          campo={"Campos Obrigatórios"}
                                        />
                                      )}
                                    {/* {errors.admi_experiencia_p2 && <AlertaErro nome={"JEAN VC É MTO INTELIGENTE:"} campo={errors.admi_experiencia_p2} />} */}
                                    {errors.admi_experiencia_p1 && (
                                      <AlertaErro
                                        nome={
                                          "Primeiro Período Contrato Experiência:"
                                        }
                                        campo={errors.admi_experiencia_p1}
                                      />
                                    )}
                                    {errors.admi_experiencia_p2 && (
                                      <AlertaErro
                                        nome={
                                          "Segundo Período Contrato Experiência:"
                                        }
                                        campo={errors.admi_experiencia_p2}
                                      />
                                    )}
                                  </Row>

                                  <Row>
                                    {!viewTicket &&
                                      <Button
                                        style={{
                                          backgroundColor: "#E30613",
                                          border: 0,
                                        }}
                                        size="lg"
                                        type="submit"
                                        form="formAdmissao"
                                      >
                                        Finalizar Admissão
                                      </Button>
                                    }
                                  </Row>
                                </Col>
                              </Row>
                            </Tab>
                          </Tabs>
                        </Form>
                      </>
                    )}
                  ></Formik>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        <Footer />
        <Modal show={loading} centered>
          <Modal.Body
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 24,
            }}
          >
            <img
              src={LogoArquivo}
              width={"20%"}
              alt={"Imagem de Nuvem"}
              loading={"lazy"}
            ></img>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {msgLoading}
              </div>

              {progresso > 0 && (
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: 10,
                  }}
                >
                  <ProgressBar
                    animated={true}
                    now={progresso}
                    variant="success"
                    label={`${progresso}%`}
                  />
                </div>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={telaAlerta} centered>
          <Modal.Header style={{ color: "#E30613", fontSize: 24 }}>
            {telaTitulo}
          </Modal.Header>
          <Modal.Body
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {telaMsg}
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ backgroundColor: "#E30613", border: 0 }}
              onClick={() => {
                if (telaMsg === "Admissão Enviada Com Sucesso!") {
                  Navigate("/departamento-pessoal/admissoes");
                } else {
                  setTelaAlerta(false);
                }
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={telaNovoFilho}
          onHide={() => {
            setTelaNovoFilho(false);
          }}
          centered
        >
          <Modal.Header style={{ color: "#30613", fontSize: 24 }} closeButton>
            Inclusão de Dependente
          </Modal.Header>
          <Modal.Body>
            <Formik
              enableReinitialize={true}
              onSubmit={async (values) => {

                const anexos = values.anexos

                if (anexos) {

                  if (!isCPF(values.cpf_filho)) {
                    ExibirAlerta("Atenção", "CPF Inválido");
                    return;
                  }

                  for (let i = 0; i < anexos.length; i++) {
                    let urlAnexo = await UploadArquivo(anexos[i])
                  }

                  await inserirFilho(values);
                } else {

                  await inserirFilho(values);
                }


              }}
              validationSchema={SchemaFilhos}
              initialValues={{
                nome_filho: "",
                data_nascimento_filho: "",
                cpf_filho: "",
                naturalidade: "",
              }}
              render={({ values, errors, touched, isValid, setFieldValue }) => (
                <>
                  <Form autoComplete="off" id="formFilhos">
                    <Row>
                      <Col sm={12} lg={12} className="mb-3">
                        <InputFieldPadrao
                          campo="nome_filho"
                          tipo="text"
                          texto="Nome do Dependente"
                        />
                        <ErroField campo="nome_filho" />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} lg={12} className="mb-3">
                        <InputFieldPadrao
                          campo="data_nascimento_filho"
                          tipo="date"
                          texto="Data de Nascimento"
                        />
                        <ErroField campo="data_nascimento_filho" />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} lg={12} className="mb-3">
                        <InputFieldPadrao
                          campo="naturalidade"
                          tipo="text"
                          texto="Naturalidade"
                        />
                        <ErroField campo="naturalidade" />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} lg={12} className="mb-3">
                        <InputFieldPadrao
                          campo="cpf_filho"
                          tipo="text"
                          texto="CPF"
                        />
                        <ErroField campo="cpf_filho" />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} lg={12} className="mb-3">
                        <label
                          htmlFor={"certidao_filho"}
                          className="form-label"
                        >
                          Documentos
                        </label>
                        <Field
                          id={"certidao_filho"}
                          name={"certidao_filho"}
                          multiple
                          type={"file"}
                          className="form-control"
                          accept="image/png, image/jpeg, application/pdf"
                          onChange={(event) => {
                            setFieldValue("anexos", event.currentTarget.files);
                          }}
                        />
                        <ErroField campo="certidao_filho" />
                      </Col>
                    </Row>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#E30613",
                          border: 0,
                          marginRight: 15,
                        }}
                        // size='sm'
                        type="submit"
                        form="formFilhos"
                      >
                        Adicionar
                      </Button>
                    </Col>
                  </Form>
                </>
              )}
            />
          </Modal.Body>
        </Modal>

        {/* <Modal
                    show={telaNovaJornada}
                    onHide={() => { setTelaNovaJornada(false) }}
                    centered
                    dialogClassName="modal-50"
                >
                    <Modal.Header style={{ color: '#30613', fontSize: 24 }} closeButton>
                        Inclusão de Jornada
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <ModalEscalas
                                isOpen={isOpenModalHorarios}
                                setIsOpen={setIsOpenModalHorarios}
                                setJornadaHorarios={setJornadaHorarios}
                                setCriarEscala={setCriarEscala}
                            />


                            <Col lg={12} className="mb-3">
                                <FormReact.Group className="mb-3" controlId="status">
                                    <FormReact.Label>Dias da Semana</FormReact.Label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        noOptionsMessage={() => 'Nenhum Dia Selecionado'}
                                        onChange={(valor) => {
                                            const lista = valor.map((option) => option.label)
                                            setJornadaDias(JSON.stringify(lista).replace('[', '').replace(']', '').replaceAll('"', ''))
                                        }}
                                        placeholder="Selecione o Dia da Semana"
                                        isMulti
                                        options={[
                                            { value: "segunda", label: "Segunda-Feira" },
                                            { value: "terca", label: "Terça-Feira" },
                                            { value: "quarta", label: "Quarta-Feira" },
                                            { value: "quinta", label: "Quinta-Feira" },
                                            { value: "sexta", label: "Sexta-Feira" },
                                            { value: "sab", label: "Sábado" },
                                            { value: "sabcomp", label: "Sábado Compensado" },
                                            { value: "domingo", label: "Domingo" },
                                        ]}
                                    />
                                </FormReact.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="mb-3">
                                <FormReact.Group className="mb-3" controlId="status">
                                    <div
                                        style={{ display: "flex", cursor: "pointer" }}
                                    >
                                        <label htmlFor="admi_cargo" className="form-label me-3">Horários</label>
                                        <Hint posicao={"top"} id={"buscar_horarios"} texto={"Buscar Horários"}>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setIsOpenModalHorarios(true)
                                                    setCriarCargo(false)
                                                }}
                                            >
                                                < BsSearch style={{ fontSize: 16, color: "#E30613" }} />
                                            </div>
                                        </Hint>
                                    </div>
                                    <FormReact.Control type="text" disabled={criarEscala ? false : true} value={jornadaHorarios} onChange={(e) => { setJornadaHorarios(e.target.value) }} />
                                </FormReact.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormReact.Group>
                                    <FormReact.Label>Observações</FormReact.Label>
                                    <FormReact.Control type="text" value={jornadaObs} onChange={(e) => { setJornadaObs(e.target.value) }} />
                                </FormReact.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 20 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0, marginRight: 15 }}
                                    onClick={() => {
                                        inserirJornada()
                                    }}
                                >
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>

                </Modal> */}
      </div>
    </>
  );
}

export default Admissao;
